exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-crypto-warning-tsx": () => import("./../../../src/pages/crypto-warning.tsx" /* webpackChunkName: "component---src-pages-crypto-warning-tsx" */),
  "component---src-pages-ethical-management-tsx": () => import("./../../../src/pages/ethical-management.tsx" /* webpackChunkName: "component---src-pages-ethical-management-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-ko-tsx": () => import("./../../../src/pages/index.ko.tsx" /* webpackChunkName: "component---src-pages-index-ko-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-old-privacy-policy-20210101-tsx": () => import("./../../../src/pages/old-privacy-policy-20210101.tsx" /* webpackChunkName: "component---src-pages-old-privacy-policy-20210101-tsx" */),
  "component---src-pages-old-privacy-policy-20220325-tsx": () => import("./../../../src/pages/old-privacy-policy-20220325.tsx" /* webpackChunkName: "component---src-pages-old-privacy-policy-20220325-tsx" */),
  "component---src-pages-old-privacy-policy-20230616-tsx": () => import("./../../../src/pages/old-privacy-policy-20230616.tsx" /* webpackChunkName: "component---src-pages-old-privacy-policy-20230616-tsx" */),
  "component---src-pages-old-privacy-policy-20240221-tsx": () => import("./../../../src/pages/old-privacy-policy-20240221.tsx" /* webpackChunkName: "component---src-pages-old-privacy-policy-20240221-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-work-guidelines-tsx": () => import("./../../../src/pages/work-guidelines.tsx" /* webpackChunkName: "component---src-pages-work-guidelines-tsx" */)
}

